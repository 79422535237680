import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";
import Fleg from "../../Assets/Header/Flag.png";
import { Link, useNavigate } from "react-router-dom";
 
const HelpDesk = () => {
  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [query, setQuery] = useState("");
  const [queryText, setText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const Submit_API = async (event) => {
    event.preventDefault();
    try {
      if (query === "") {
        alert("Enter Your Subject !!");
        return;
      } else if (queryText === "") {
        alert("Enter Your Query !!");
        return;
      }
      let responce = await API.post(
        "HelpDesk",
        {
          uid: userId,
          query: query,
          querTypeText: queryText,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Submit-->", responce);
      alert("Query has been sent succrssfully !!");
      Report_API();
      setQuery("");
      setText("");
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Report_API = async () => {
    try {
      let responce = await API.post(
        "GetHelpdeskuser",
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      //console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          status: item.status,
          edate: item.edate[0],
          subject: item.subject,
          reply_message: item.reply_message,
          ticket_id: item.ticket_id,
          query: item.query,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
//Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }
  // Notification end

  useEffect(() => {
    Report_API();
    getNotification();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "edate" },
      { Header: "Subject", accessor: "query" },
      { Header: "Query", accessor: "subject" },
      { Header: "Admin Reply", accessor: "reply_message" },
      { Header: "Ticket Id", accessor: "ticket_id" },
    ],
  });

  function handleQuery(e) {
    const { value } = e.target;
    setQuery(DOMPurify.sanitize(value));
  }

  function handleQueryText(e) {
    const { value } = e.target;
    setText(DOMPurify.sanitize(value));
  }
  // Toggle sidebar function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="container-fluid NewDashboard HelpDesk">
      <div className="row">

        <div className={` ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          <Sidebar />
        </div>
        <div className="col-12 ms-md-auto px-0">
          <div className="contentside">
            <div className="row mx-0">
              <div className="col-12 px-0">
                <header className="header ps-0">
                  <div className="hdleft d-flex align-items-center">

                    <div className="namelogo ms-3">Raise A Query</div>
                  </div>
                  <div className="rightside">
                  <div className="dropdown dropdow1 d-none d-sm-block">
                        <a
                          className="btn btnsecondary text-back"
                          href="/Notification"
                        >
                        🔔 <span className="btn headerbtn" > {NotificationCount} </span>
                        </a>
                      </div>
                    <div className="dropdown dropdow2 d-none d-md-block">
                      <Link className="btn btnsecondary dropdown-toggle" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="text1">
                          <p className="mb-1">ID Number</p>
                          <p className="mb-0 fw-bold text-start">{userId} </p>
                        </span>
                        <span className="icon">
                          <div className="name"><img className="w-100 h-100" src="https://metaswift.ai/assets/img/user_pic.png" alt="" /></div>
                        </span>
                      </Link>

                      <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                      </ul>
                    </div>
                    <button className="btn headerbtn">Connect Wallet</button>
                    <button className="btn border-0 togglebtn" id="togglebtn" onClick={toggleSidebar}>
                      <i className={`fas fa-bars ${isSidebarOpen ? '' : 'collapsed'}`}></i>
                    </button>
                  </div>
                </header>
              </div>
              <div className="col-md-7 mx-auto">
                <div className="contentsidesec w-100 pt-3">
                  <div className="row mx-0">
                    <div className="col-xl-12">
                      <form onSubmit={Submit_API} id="queryForm">
                        <div className="bg pad-tp-15">
                          <label className="text-black">Subject</label>
                          <input
                            type="text"
                            className="floating-input select_bg"
                            id="Subject"
                            placeholder="Subject"
                            onChange={handleQuery}
                            value={query}
                          />
                        </div>
                        <div className="bg pad-tp-15">
                          <label className="text-black">Your Query</label>
                          <textarea
                            className="floating-input select_bg"
                            id="query"
                            rows={3}
                            value={queryText}
                            onChange={handleQueryText}
                          />
                          {/* <textarea
                            name="msg"
                            className="form-elm"
                            cols={30}
                            rows={5}
                            placeholder="Write Message"
                            required=""
                            defaultValue={""}
                          /> */}
                        </div>
                        {/* <div className="bg pad-tp-15">
                        <div className="flexbox">
                          <div className="flex-md-10">
                            <input
                              className="size-md"
                              type="checkbox"
                              name="terms"
                              required=""
                            />
                        </div>
                        <div className="flex-md-90">
                          <span>
                            I Accept all the terms of use, privacy policy. I Also
                            acknowledge that, Above information is accurate &amp;
                            authentic as per my current knowledge.
                          </span>
                        </div>
                      </div>
                    </div> */}
                        <div className="bg pad-tp-15 pad-bt-10">
                          <button type="submit" className="btn btn-primary mt_5">
                            Submit Query
                          </button>
                        </div>
                      </form>
                      <div className="break20" />
                    </div>
                    <div class="breadcrumb-header justify-content-between">
                      <div class="left-content">
                        <span class="main-content-title mg-b-0 mg-b-lg-1 text-black">
                          HelpDesk List
                        </span>
                      </div>
                    </div>
                    <div className="bg">
                      <div className="bg pos-rel pad-bt-20 marg-bt-20 notfictiontextable">
                        <Table data={[...currentPost]} columns={reportData.cols} />
                        <Table_Buttons
                          indexOfFirstPage={indexOfFirstPage}
                          indexOfLastPost={indexOfLastPost}
                          setcurrentPage={setcurrentPage}
                          currentPage={currentPage}
                          totalData={ReportApi.length}
                          listPerpage={listPerpage}
                          handleRowsPerPageChange={setlistPerpage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpDesk;
