import React, { useState, useEffect } from "react";
// import gif from "../Spinner/loader.gif";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../Utility/contract";
import { updateAuth } from "../../Redux/AuthSlice";
import Fleg from "../../Assets/Header/Flag.png";
import { Link, useNavigate } from "react-router-dom";

import {
  Abi_Main,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_USDT,
} from "../../Utility/ContractAddress";
import { Spinner } from "react-bootstrap";

export default function Activate() {
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);
  const [IsUpgrade, setIsUpgrade] = useState(0);
  const [IsEligible, setIsEligible] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle sidebar function
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const Loader2 = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>
        <button className="activate_btn">
          Wait for 15 minutes after last transaction
        </button>
      </p>
    </div>
  );

  const CalculateValue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(Amount);
    setAmount(Totalvalue);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      console.log("getUserDetails", responce);

      setIsUpgrade(responce.CurrentPackageAmount);
      setWallet_Address(responce.btcaddress);
      setIsEligible(responce.TransactionStatus);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      //console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setUSDT(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Activate = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (USDT < Amount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else {
            // let amountValue = window.web3.utils.toWei(Amount.toString()); // parseFloat(Amount) * 1000000000000000000; // window.web3.utils.fromWei(Amount.toString());

            let amountValue = window.web3.utils.toWei(
              (Amount + 2).toString(),
              "ether"
            );

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );

              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, amountValue)
                .send({
                  from: acc,
                  // gasLimit: approveBlock.gasLimit,
                  // gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Approved Succesful");
              let amount = Amount.toString();
              let sellCall = await contract_Main.methods
                .buyRouter(amount)
                .send({
                  from: acc,
                  // gasLimit: approveBlock.gasLimit,
                  // gasPrice: await window.web3.eth.getGasPrice(),
                });

              let res = await API.post(
                "activationtemp5050",
                {
                  uid: userId,
                  address: acc,
                  amount: Amount,
                  tokenamount: 0,
                  trxvalue: 0,
                  transaction: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwtToken}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("activation", res.data);
              toast.success("Transaction Succesful");
              if (res.data.success == true) {
                toast.success("Activation is under process");
                navigate("/dashboard");
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  const Upgrade = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (IsUpgrade > Amount) {
            toast.error("You cannot upgrade with lower package");
            setloading(false);
          } else if (USDT < Amount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else {
            // let amountValue = window.web3.utils.toWei(Amount.toString()); // parseFloat(Amount) * 1000000000000000000; // window.web3.utils.fromWei(Amount.toString());

            let amountValue = window.web3.utils.toWei(
              (Amount + 2).toString(),
              "ether"
            );

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );

              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, amountValue)
                .send({
                  from: acc,
                  // gasLimit: approveBlock.gasLimit,
                  // gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Approved Succesful");
              let amount = Amount.toString();
              let sellCall = await contract_Main.methods
                .buyRouter(amount)
                .send({
                  from: acc,
                  // gasLimit: approveBlock.gasLimit,
                  // gasPrice: await window.web3.eth.getGasPrice(),
                });

              let res = await API.post(
                "upgradetemp5050",
                {
                  uid: userId,
                  address: acc,
                  amount: Amount,
                  tokenamount: 0,
                  trxvalue: 0,
                  transaction: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwtToken}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("Upgrade", res.data);
              toast.success("Transaction Succesful");
              if (res.data.success == true) {
                toast.success("Upgrade is under process");
                navigate("/dashboard");
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };
  //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }
  // Notification end

  useEffect(() => {
    USDTBalance();
    GetUserDetails();
    getNotification();

    // Set an interval to check the wallet connection every 5 seconds
    setInterval(walletConnected, 5000); // 5000 milliseconds = 5 seconds
  }, []);

  return (
    <>
      <div className="container-fluid NewDashboard">
        <div className="row">

          <div className={` ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <Sidebar />
          </div>
          <div className="col12 ms-md-auto px-0">
            <div className="contentside">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <header className="header ps-0">
                    <div className="hdleft d-flex align-items-center">

                      <div className="namelogo ms-3">Activation</div>
                    </div>
                    <div className="rightside">
                      <div className="dropdown dropdow1 d-none d-sm-block">
                        <a
                          className="btn btnsecondary text-back"
                          href="/Notification"
                        >
                          🔔 <span className="btn headerbtn" > {NotificationCount} </span>
                        </a>
                      </div>
                      <div className="dropdown dropdow2 d-none d-md-block">
                        <Link className="btn btnsecondary dropdown-toggle" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <span className="text1">
                            <p className="mb-1">ID Number</p>
                            <p className="mb-0 fw-bold text-start">{userId} </p>
                          </span>
                          <span className="icon">
                            <div className="name"><img className="w-100 h-100" src="https://metaswift.ai/assets/img/user_pic.png" alt="" /></div>
                          </span>
                        </Link>

                        <ul className="dropdown-menu">
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        </ul>
                      </div>
                      <button className="btn headerbtn">Connect Wallet</button>
                      <button className="btn border-0 togglebtn" id="togglebtn" onClick={toggleSidebar}>
                        <i className={`fas fa-bars ${isSidebarOpen ? '' : 'collapsed'}`}></i>
                      </button>
                    </div>
                  </header>
                </div>
                <div className="col-12">
                  <div className="contentsidesec w-100 pt-3">
                    <div className="row mx-0">
                      <div className="col-md-5 card1 mx-auto Zindexcard Activatecard">
                        <div className="" role="document">
                          <div className="modal-content10">
                            <div className="" style={{ paddingBottom: 10 }}>
                              <div className="avlbal">
                                <h3
                                  id="exampleModal3Label2"
                                  style={{ textAlign: "center" }}
                                >
                                  Activate / Upgrade
                                </h3>
                                <div className="Model_text_inner activation_Item">
                                  <div className="rate_item text-white">
                                    <span className="text-white"> Available USDT : </span>
                                    <input
                                      type="text"
                                      className="inputes ms-0"
                                      id=""
                                      value={USDT}
                                      defaultValue={0}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="activation_modal">
                              <div className="popup_net">
                                <div className="addNum">
                                  <div className="trxnumber">
                                    <input
                                      type="text"
                                      readOnly=""
                                      value={Amount}
                                      id="txtamount2"
                                      className="form5"
                                      defaultValue={0}
                                    />
                                    <span className="text-white" style={{ marginLeft: 10 }}>
                                      Package Amount
                                    </span>
                                  </div>
                                  <div className="trxnumber">
                                    <input
                                      type="text"
                                      readOnly=""
                                      value={Amount}
                                      className="form5"
                                      defaultValue={0}
                                    />
                                    <span className="text-white" style={{ marginLeft: 10 }}>
                                      USDT Amount
                                    </span>
                                  </div>
                                  <div className="btn-list btn_set_item d-flex justify-content-center mb-3">
                                    {/* <a
                                  onClick={() => CalculateValue(50)}
                                  href="javascript:void(0);"
                                  className="btn btn-sm btn-primary"
                                >
                                  {" "}
                                  50 $
                                </a> */}

                                    <a
                                      onClick={() => CalculateValue(100)}
                                      href="javascript:void(0);"
                                      className="btn btn-sm btn-primary text-white"
                                    >
                                      {" "}
                                      $ 100
                                    </a>

                                    <a
                                      onClick={() => CalculateValue(500)}
                                      href="javascript:void(0);"
                                      className="btn btn-sm btn-primary text-white"
                                    >
                                      {" "}
                                      $ 500
                                    </a>
                                    <a
                                      onClick={() => CalculateValue(1000)}
                                      href="javascript:void(0);"
                                      className="btn btn-sm btn-primary text-white"
                                    >
                                      {" "}
                                      $ 1000
                                    </a>
                                    <a
                                      onClick={() => CalculateValue(5000)}
                                      href="javascript:void(0);"
                                      className="btn btn-sm btn-primary text-white"
                                    >
                                      {" "}
                                      $ 5000
                                    </a>
                                    <a
                                      onClick={() => setAmount(0)}
                                      href="javascript:void(0);"
                                      className="btn btn-sm btn-primary text-white"
                                    >
                                      Reset
                                    </a>
                                  </div>
                                  <input
                                    type="hidden"
                                    id="amount"
                                    name="name"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      className="trxnumber"
                                      style={{ textAlign: "center" }}
                                    >
                                      {loading == true ? (
                                        <>
                                          <Loader />
                                        </>
                                      ) : (
                                        <>
                                          {parseInt(IsEligible) == 1 ? (
                                            <>
                                              {parseFloat(IsUpgrade) > 0 ? (
                                                <button
                                                  className="activate_btn"
                                                  onClick={() => Upgrade()}
                                                >
                                                  Upgrade
                                                </button>
                                              ) : (
                                                <button
                                                  className="activate_btn"
                                                  onClick={() => Activate()}
                                                >
                                                  Activate
                                                </button>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <Loader2 />
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
