import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import Fleg from "../../Assets/Header/Flag.png";
import { Link, useNavigate } from "react-router-dom";

export default function Activate_History() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const ActiveHistory_API = async () => {
    try {
      let responce = await API?.post(
        `/activationHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          pool: "1",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Ahistory", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          Amount: item.Amount,
          remark: item.remark,
          type: item.type,
          dd: item.dd,
          transactionHash: (
            <a
              href={`https://bscscan.com/tx/${item.pinnumber}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00544a" }}
            >
              View Txn
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }
  // Notification end

  useEffect(() => {
    ActiveHistory_API();
    getNotification();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Investment", accessor: "Amount" },
      { Header: " Remark", accessor: "remark" },
      { Header: "Txn", accessor: "transactionHash" },
      { Header: "Date & Time", accessor: "dd" },
    ],
  });
  // Toggle sidebar function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="container-fluid NewDashboard Activate_History">
        <div className="row">
          <div
            className={` ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
          >
            <Sidebar />
          </div>
          <div className="col-12 ms-md-auto px-0">
            <div className="contentside">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <header className="header ps-0">
                    <div className="hdleft d-flex align-items-center">
                      <div className="namelogo ms-3">Activation History</div>
                    </div>
                    <div className="rightside">
                      <div className="dropdown dropdow1 d-none d-sm-block">
                        <a
                          className="btn btnsecondary text-back"
                          href="/Notification"
                        >
                          🔔  <span className="btn headerbtn" > {NotificationCount} </span>
                        </a>
                      </div>
                      <div className="dropdown dropdow2 d-none d-md-block">
                        <Link
                          className="btn btnsecondary dropdown-toggle"
                          to=""
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="text1">
                            <p className="mb-1">ID Number</p>
                            <p className="mb-0 fw-bold text-start">{userId} </p>
                          </span>
                          <span className="icon">
                            <div className="name">
                              <img
                                className="w-100 h-100"
                                src="https://metaswift.ai/assets/img/user_pic.png"
                                alt=""
                              />
                            </div>
                          </span>
                        </Link>

                        <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to="#">
                              <span>
                                {" "}
                                <img
                                  className="w-100 h-100"
                                  src={Fleg}
                                  alt=""
                                />
                              </span>
                              USA
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              <span>
                                {" "}
                                <img
                                  className="w-100 h-100"
                                  src={Fleg}
                                  alt=""
                                />
                              </span>
                              USA
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              <span>
                                {" "}
                                <img
                                  className="w-100 h-100"
                                  src={Fleg}
                                  alt=""
                                />
                              </span>
                              USA
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <button className="btn headerbtn">Connect Wallet</button>
                      <button
                        className="btn border-0 togglebtn"
                        id="togglebtn"
                        onClick={toggleSidebar}
                      >
                        <i
                          className={`fas fa-bars ${isSidebarOpen ? "" : "collapsed"
                            }`}
                        ></i>
                      </button>
                    </div>
                  </header>
                </div>
                <div className="col-12">
                  <div className="contentsidesec w-100 pt-3">
                    <div className="row mx-0">
                      <div class="col-xl-12">
                        <div class="card">
                          <div class="card-body">
                            {/* <div class="row mt-3">
                          <div class="col-md-2">
                            <label> User Id</label>
                            <input
                              type="text"
                              name="from_date"
                              id="uid"
                              class="form-control"
                              placeholder="Enter User Id"
                            />
                          </div>
                          <div class="col-md-2 mt-2">
                            <div className="mtr_space"></div>
                            <input
                              type="submit"
                              name="to_date"
                              value="Search"
                              class="btn btn-primary mt_5"
                            />
                          </div>
                        </div> */}
                            <div class="table-responsive">
                              <div
                                id="myAssets_wrapper"
                                class="dataTables_wrapper dt-bootstrap5 no-footer"
                              >
                                <div class="row">
                                  <div class="col-sm-12">
                                    <Table
                                      data={[...currentPost]}
                                      columns={matching_income.cols}
                                    />
                                    <Table_Buttons
                                      indexOfFirstPage={indexOfFirstPage}
                                      indexOfLastPost={indexOfLastPost}
                                      setcurrentPage={setcurrentPage}
                                      currentPage={currentPage}
                                      totalData={referralApi.length}
                                      listPerpage={listPerpage}
                                      handleRowsPerPageChange={setlistPerpage}
                                    />
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
