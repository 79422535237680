import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar55 from "./Navbar55";
import { FaFacebookF, FaTelegram, FaTwitter } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
function Home() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    sessionStorage.clear();
    // setImagePopupVisible(true);
  }, []);
  return (
    <div class="home-blockchain">
      <div className="progress-wrap style-2">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      <div className="body_wrap">
        {/* navbar start */}
        <Navbar55 />

        {/* navbar end */}
        <main>
          {/* hero start */}
          <section
            className="hero hero__blockchain pos-rel bg_img"
          // data-background="assets/img/bg/blockchain_hero_bg.png"
          // style={{backgroundImage:(HomePic)}}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="blockchain-hero__content">
                    <h1 className="title text-80 mb-35 fw-bold -tracking-2/4 text-black headinHero">
                      WELCOME TO <span>CELEX WORLD</span> Introduction
                    </h1>
                    <p className="mb-20 text-20 leading-30 text-black">
                      Celex is a GERMANY based organisation which has enterd the
                      market to build its community through a refferal program
                      and their ecosystem it is bringing celex chain, celex
                      wallet, celex exchange and also coming up with celex
                      gaming portal.
                    </p>
                    <p className="mb-50 text-20 leading-30 text-black">
                      Celex is a digital currency, which is an alternative form
                      of payment created using encryption algorithms. the use of
                      encryption technologies means that cryptocurrencies
                      function both as a currency and as a virtual accounting
                      system.
                    </p>
                    <div className="btns">
                      <a
                        className="blc-btn"
                        target="_blank"
                        href="/assets/img/CELEXWorldd.pdf"
                      >
                        white paper
                      </a>
                      <a
                        className="blc-btn widse"
                        target="_blank"
                        href="/assets/img/CELEXWorld.pdf"
                      >
                        PDF
                      </a>

                      {/* <a
                        className="blc-btn blc-btn--white pdfeee"
                        href="/assets/img/CELEXWorld.pdf"
                        target="_blank"
                      >
                        PDF
                        <span
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: 25, height: 25 }}
                        >
                         
                        </span>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="hero__blockchain-icon pos-rel">
                    <div className="icon ul_li icon--1 absolute">
                      <span className="hometextrigt">Secure &amp; Safe</span>
                      <img src="assets/img/icon/sc.svg" alt="" />
                    </div>
                    <div className="icon ul_li icon--2 absolute">
                      <span className="hometextrigt">Tested</span>
                      <img src="assets/img/icon/sc.svg" alt="" />
                    </div>
                    <div className="icon ul_li icon--3 absolute">
                      <span className="Trustworthy hometextrigt">
                        Trustworthy
                      </span>
                      <img src="assets/img/icon/sc.svg" alt="" />
                    </div>
                    <div className="icon ul_li icon--4 absolute">
                      <img src="assets/img/icon/sc.svg" alt="" />
                      <span className="hometextrigt">Optimized</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* about end */}
          <section id="about">
            <div className="container">
              <div className="row mb-70 mt-30">
                <div className="aboutMain sec-title style2 text-center mb-20">
                  <h2 className="sec-title__title text-50 mb-25">
                    ABOUT CELEX
                  </h2>
                </div>
                <div class="about-block">
                  <div class="section-subtitle animated fadeInUp">
                    Welcome To Celex World: Empowering The Future Of Finance
                  </div>
                  <article class="section-article mCS_destroyed">
                    <p class="animated fadeInUp">
                      Celex coin is being launched in May 2024 with total supply
                      of 11,11,111 which is on BSC Blockchain. This token has
                      been innovated for the Celex World Refferal which will be
                      pancakeswap list from the first day itself where you can
                      sell it only. You cannot buy this token, you can only earn
                      it buy joining the refferal program of Celex World.
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </section>
          {/* user option start */}
          <section className="user-option pb-110">
            <div className="container">
              <div className="sec-title style2 text-center mb-20">
                <h2 className="sec-title__title text-50 mb-25">
                  OUR MISSION & VISION
                </h2>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div className="user-option__item">
                    <div className="icon pos-rel ">
                      <img src="assets/img/icon/up_01.svg" alt="" />
                    </div>
                    <p class="text-white">
                      Celex community’s mission is to build a huge community
                      through its refferal program and give people the
                      opportunity to earn great income and improve their wealth
                      and along with this, our target is to build Celex as the
                      world’s largest community.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="user-option__img text-center">
                    <img src="assets/img/bg/uo_bg.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="user-option__item style2">
                    <div className="icon pos-rel">
                      <img src="assets/img/icon/up_02.svg" alt="" />
                    </div>
                    <p class="text-white">
                      The vision of Celex community is that in the coming time,
                      we will be one of the top five projects in the crypto
                      market and the number of people doing all our projects
                      will be the highest in comparison to other project and the
                      name of the most trusted projects is the Celex community
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* user option end */}
          {/* map start */}
          <section className="blc-about pb-80">
            <div className="container">
              <div className="row align-items-center">
                <div className="sec-title style2 mb-40">
                  <h2 className="sec-title__title text-center text-50 mb-25">
                    INITIAL START WITH
                  </h2>
                </div>
                <div className="col-lg-6">
                  <div className="blc-about__img pos-rel text-center mapEmg  wow fadeInLeft">
                    <img src="/assets/img/map.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="blc-about__content wow fadeInRight"
                    data-wow-delay="100ms"
                  >
                    <div className="d-flex align-items-center justify-content-around flex-wrap">
                      <a
                        href="https://maps.app.goo.gl/TWgTPi6BbU4oL3kS7"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Bangladesh
                      </a>
                      <a
                        href="https://maps.app.goo.gl/egb2fCYHdnX9LJtu9"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Nigeria
                      </a>
                      <a
                        href="https://maps.app.goo.gl/6bL8GjBmpUJW5nGZ6"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Australia
                      </a>
                      <a
                        href="https://maps.app.goo.gl/XZPShPBY7XrQzTti8"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        India
                      </a>
                      <a
                        href="https://maps.app.goo.gl/K5VPof38WeCHPW9N7"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Pakistan
                      </a>
                      <a
                        href="https://maps.app.goo.gl/NMJywVBicXSje1M87"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Phillipines
                      </a>
                      <a
                        href="https://maps.app.goo.gl/ir7r7mYHe2Fhb1eJA"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Germany
                      </a>
                      <a
                        href="https://maps.app.goo.gl/PaPKzAek2BhEk9nq6"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Canada
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* map end */}
          {/* team start */}
          <section
            id="team"
            className="team team__bg pb-120"
          // data-background="assets/img/bg/team_sec_bg.png"
          >
            <div className="container">
              <div className="sec-title style2 text-center mb-60">
                <h2 className="sec-title__title text-50 mb-25">
                  Our Dedicated Team
                </h2>
                <p>Always ready our team to help you</p>
              </div>
              <div className="row mt-none-30">
                <div className="col-xl-6 col-lg-6 col-md-6 mt-30">
                  <div className="team__single text-center pos-rel">
                    <div className="avatar">
                      <img src="assets/img/team/person.jpeg" alt="" />
                    </div>
                    <div className="content">
                      <h3>Mr. Gabby Obera</h3>
                      <span>CEO</span>
                    </div>
                    <div className="team__social style2 ul_li_center mt-30">
                      <ul className="team__social-link link-left ul_li">
                        <li>
                          <a href="#!">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                      </ul>
                      <span className="h-icon">
                        <i className="far fa-plus" />
                      </span>
                      <ul className="team__social-link link-right ul_li">
                        <li>
                          <a href="#!">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="rating">
                      <img src="assets/img/icon/star.svg" alt="" />
                      5.0
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-30">
                  <div className="team__single style2 text-center">
                    <div className="avatar">
                      <img src="assets/img/team/person2.jpeg" alt="" />
                    </div>
                    <div className="content">
                      <h3>MR. Peter Ross</h3>
                      <span>CMO</span>
                    </div>
                    <div className="team__social style2 ul_li_center mt-30">
                      <ul className="team__social-link link-left ul_li">
                        <li>
                          <a href="#!">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                      </ul>
                      <span className="h-icon">
                        <i className="far fa-plus" />
                      </span>
                      <ul className="team__social-link link-right ul_li">
                        <li>
                          <a href="#!">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="rating">
                      <img src="assets/img/icon/star.svg" alt="" />
                      5.0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* team end */}
          {/* token update start */}
          <section className="token-update pb-130" id="token_details">
            <div className="container">
              <div className="sec-title style2 text-center mb-60">
                <h2 className="sec-title__title text-50 mb-25">
                  <img
                    className=" tknDtails"
                    src="/assets/img/icon.png"
                    alt=""
                  />{" "}
                  TOKEN DETAILS
                </h2>
              </div>
              <div className="row mt-none-30">
                <div className="col-lg-6 mt-30">
                  <ul className="token-update__data list-unstyled">
                    <li>
                      <span>Token Name</span>
                      <strong>Celex coin</strong>
                    </li>
                    <li>
                      <span>Total Supply</span>
                      <strong>11,11,111</strong>
                    </li>
                    <li>
                      <span>Exchange </span>
                      <strong>Pancakeswap</strong>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mt-30">
                  <ul className="token-update__data list-unstyled">
                    <li>
                      <span>Token Ticker</span>
                      <strong>Celx</strong>
                    </li>
                    <li>
                      <span>Blockchain</span>
                      <strong>BNB Chain</strong>
                    </li>
                    <li>
                      <span>Trade</span>
                      <strong>Celx/Usdt</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* token update end */}
          {/* CELEX  LISTING LIST  Section start */}
          <section className="blc-about pb-80 clexlistingbg " id="celexlist">
            <div className="container">
              <div className="row align-items-center">
                <div className="sec-title style2 mb-40">
                  <h2 className="sec-title__title text-center text-50 mb-25 text-white"> CELEX  LISTING LIST  </h2>
                </div>
                <div className="col-12">
                  <div
                    className="blc-about__content wow fadeInRight"
                    data-wow-delay="100ms"
                  >
                    <div className="d-flex align-items-center justify-content-around flex-wrap">
                      <a
                        href="https://www.dextools.io/app/en/bnb/pair-explorer/0x9e9514865532a3635ce639d3c022e22dfc38b2d3?t=1722672647531"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Dextools.Io
                      </a>
                      <a
                        href="https://coinmooner.com/coin/celex-coin-celx"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinmooner.com
                      </a>
                      <a
                        href="https://coinbrain.com/coins/bnb-0xae9e48c204b2bb47da7aaacd4cb110d79888a9b0"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinbrain.com
                      </a>
                      <a
                        href="https://coinvote.cc/en/coin/celex-coin"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinvote.cc
                      </a>
                      <a
                        href="https://coinsniper.net/coin/66152"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinsniper.net
                      </a>
                      <a
                        href="https://cntoken.io/coin/44852"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        cntoken.io
                      </a>
                      <a
                        href="https://coindiscovery.app/coin/celex-coin/overview"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coindiscovery.app
                      </a>
                      <a
                        href="https://coinhunt.cc/coin/665438ca9a4457e014f51854"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinhunt.cc
                      </a>
                      <a
                        href="https://www.coinscan.com/tokens/bsc/0x9e9514865532a3635ce639d3c022e22dfc38b2d3"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        coinscan.com
                      </a>
                      <a
                        href="https://gemsradar.com/coins/celex-coin"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Gemsradar.com
                      </a>
                      <a
                        href="https://apespace.io/bsc/0x9e9514865532a3635ce639d3c022e22dfc38b2d3"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Apespace.io
                      </a>
                      <a
                        href="https://coinboom.net/coin/celex-coin"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinboom.net
                      </a>
                      <a
                        href="https://icogems.com/coin/CeleX-coin-coin"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Icogems.com
                      </a>
                      <a
                        href="https://top100token.com/address/0xAE9e48C204b2bB47DA7Aaacd4Cb110D79888A9b0"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Top100token.com
                      </a>
                      <a
                        href="https://poocoin.app/tokens/0xae9e48c204b2bb47da7aaacd4cb110d79888a9b0"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Poocoin.app
                      </a>
                      <a
                        href="https://coinsniper.net/coin/66152"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinsniper.net
                      </a>
                      <a
                        href="https://apespace.io/bsc/0xae9e48c204b2bb47da7aaacd4cb110d79888a9b0"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Apespace.io
                      </a>
                      <a
                        href="https://coinmarketcap.com/dexscan/bsc/0x9e9514865532a3635ce639d3c022e22dfc38b2d3/"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Coinmarketcap.com
                      </a>
                      <a
                        href="https://www.geckoterminal.com/bsc/pools/0x9e9514865532a3635ce639d3c022e22dfc38b2d3"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Geckoterminal.com
                      </a>
                      <a
                        href="https://pancakeswap.finance/swap?outputCurrency=0xAE9e48C204b2bB47DA7Aaacd4Cb110D79888A9b0"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        Pancakeswap.finance
                      </a>
                      <a
                        href="https://www.dx.app/dxlock/view/liquidity-locker?address=0x2077CAc858920cC18B8fe2facA8e0c4d1EDbe0a1&chain=56"
                        target="_blank"
                        className="btn px-3 py-2 rounded-pill fw-bold text-white d-flex align-items-center justify-content-center border btn-gradient mb-3"
                      >
                        DEx look link
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* CELEX  LISTING LIST  Section start */}

          {/* Celex Exchange start */}
          <section className="blc-about pb-80" id="celex_exchange">
            <div className="container">
              <div className="row align-items-center">
                <div className="sec-title style2 mb-40">
                  <h2 className="sec-title__title text-center text-50 mb-25">
                    Celex Exchange
                  </h2>
                </div>

                <div className="col-lg-6">
                  <div
                    className="blc-about__content wow fadeInRight text-black"
                    data-wow-delay="100ms"
                  >
                    <p>
                      Celex is a new generation Exchange offering the most
                      indemand services on the market , Spot exchange , margin
                      trading ,crypto-backed loans, peer to peer, future trading
                      24/7 support . We work best to be the first.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blc-about__img pos-rel text-center mapEmg  wow fadeInLeft">
                    <img src="/assets/img/Celex-Exchange.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Celex Exchange end */}
          {/* Celex Wallet start */}
          <section className="blc-about pb-80 pt-50">
            <div className="container">
              <div className="row align-items-center">
                <div className="sec-title style2 mb-40">
                  <h2 className="sec-title__title text-center text-50 mb-25">
                    Celex Wallet
                  </h2>
                </div>

                <div className="col-lg-6">
                  <div
                    className="blc-about__content wow fadeInRight"
                    data-wow-delay="100ms"
                  >
                    <p class="mt-md-4 text-black">
                      Celex Wallet is a imprisonment software wallet that offers
                      an allin-one experience. It allow users to securely send,
                      receive, exchange, and hold digital assets, including
                      cryptocurrencies and NFTs. Celex wallet is a software
                      cryptocurrency wallet used to interact with the Celex
                      blockchain. it allows users to access their wallet through
                      a browsers extension or mobile app, Available for ios,
                      Android, and desktop browsers. which can then be used to
                      interact with decentralized applications.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blc-about__img pos-rel text-center mapEmg  wow fadeInLeft">
                    <img
                      className="w-75 h-75"
                      src="/assets/img/wallet.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Celex Wallet end */}
          {/* Celex Gaming start */}
          <section className="blc-about pb-80 pt-50">
            <div className="container">
              <div className="row align-items-center">
                <div className="sec-title style2 mb-40">
                  <h2 className="sec-title__title text-center text-50 mb-25">
                    Celex Online Gaming
                  </h2>
                </div>

                <div className="col-lg-6">
                  <div
                    className="blc-about__content wow fadeInRight text-black"
                    data-wow-delay="100ms"
                  >
                    <p>
                      Welcome to the Celex Games where the world of online
                      gaming meets the thrill of intellectual exploration.
                      Founded with a passion for combining entertainment and
                      education, our platform is dedicated to providing a
                      diverse array of engaging games that challenge and inspire
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="blc-about__img pos-rel text-center mapEmg  wow fadeInLeft">
                    <img
                      className="w-75 h-75"
                      src="/assets/img/gaming.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Celex Gaming end */}
          <section className="blc-about pb-80 pt-50">
            <div className="container">
              <div className="row align-items-center">
                <div className="sec-title style2 mb-40">
                  <h2 className="sec-title__title text-center text-50 mb-25">
                  CELEX ARBITRAGE
                  </h2>
                </div>
                <div className="col-lg-6">
                  <div className="blc-about__img pos-rel text-center mapEmg  wow fadeInLeft">
                    <img
                      className="w-100 h-100"
                      src="/assets/img/robotdolor.jpg
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="blc-about__content wow fadeInRight"
                    data-wow-delay="100ms"
                  >
                    <p className="text-black">
                    The simultaneous buying and selling of securities, currency, or commodities in different markets or in derivative forms in order to take advantage of differing prices for the same asset.
                    </p>
                    <p className="text-black">Cryptocurrency arbitrage refers to the practice of taking advantage of price contrast for the same asset on different crypto exchanges or markets.</p>
                    <p className="text-black">Crypto prices can vary significantly between exchanges due to factors such as market demand, trading volume, and regional differences in regulation. These price differentials give traders the opportunity to buy low on one exchange and sell high on another to take advantage of the price differential.</p>
                    <p className="text-black">Arbitrage opportunities related to cryptocurrencies can arise not only due to the volatility and inefficiency of cryptocurrency exchanges, but also due to the decentralized and often fragmented nature of the market.</p>
                    <p className="text-black">Celex recently introduced the arbitrage technique to its users and declared arbitrage as its  source of income. </p>
                  </div>
                </div>
                
              </div>
            </div>
          </section>
          {/* faq start */}
          <section id="faq" className="faq pb-75">
            <div className="container">
              <div className="sec-title style2 text-center mb-40">
                <h2 className="sec-title__title text-50 mb-25">
                  Frequently Asked Questions
                </h2>
                <p className="text-black">Have questions? We have answers!</p>
              </div>
              <div className="faq__blockchain">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1. What is CELEX?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body ">
                        CELEX is a cutting-edge digital currency that harnesses
                        the power of blockchain technology, offering users a
                        range of features and opportunities in the world of
                        decentralized finance (DeFi).
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. How do I get Started with CELEX?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        CELEX is a complitly decentralized project which gives
                        you financial freedom while referring people and in term
                        of return of investment and its giving you the platform
                        of gaming where you are earning while playing the games.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. Is CELEX secure?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Yes, CELEX is secure when accessed through authorized
                        channels such as university libraries or direct
                        purchases from official sources, ensuring data integrity
                        and user privacy. Always follow institutional guidelines
                        for secure usage
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. On which blockchain is CELEX based?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        CELEX is built on the Binance Smart Chain (BEP-20),
                        leveraging its robust infrastructure to provide a secure
                        and efficient environment for token transactions and
                        operations.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        5. How can I maximize my returns through the CELEX
                        project?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        CELEX offers a range of avenues to reap profits and
                        enjoy the associated benefits. By participating in our
                        active referral program, you have the opportunity to
                        earn rewards by introducing new users to the CELEX
                        ecosystem.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* faq end */}
          {/* contact us start */}
          <section id="contact_us" className=" pb-75">
            <div className="container">
              <div className="sec-title style2 text-center mb-40">
                <h2 className="sec-title__title text-50 mb-25 text-black">
                  Get In Touch Now
                </h2>
                <h5 className="text-black">Email : support@celexworld.info</h5>
              </div>
              <form className="faqsec px-3 py-5 rounded-3" action="">
                <div className="row mx-0 g-3 align-items-center">
                  <div className="col-md-6">
                    <input
                      className="form-control shadow-none"
                      placeholder="Your Name"
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control shadow-none"
                      placeholder="support@celexworld.info"
                      type="text"
                    />
                  </div>
                  <div className="col-12">
                    <textarea style={{border: "1px solid black", background: "#1d1d3e"}}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Enter your message...."
                      rows={5}
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    <button className="btn btnform rounded-pill py-3 px-4 text-white" style={{background: "#00b187"}}>
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
          {/* contact us end */}
        </main>
        {/* footer start */}
        <div className="footer__copyright-blc ul_li_between">
          <div className="statistic-list-wrap">
            <div className="container">
              <ul className="statistic-list ps-0 pb-1 justify-content-md-start justify-contnet-center">
                <li className="statistic-list-unit animated fadeInUp">
                  <div className="statistic-box1">
                    <a
                      href="https://www.facebook.com/profile.php?id=61559715766085&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <div className="text-center">
                        <FaFacebookF
                          className="fa fa-facebook statistic-box__icon"
                          aria-hidden="true"
                        />
                        <div className="statistic-box__title">Facebook</div>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="statistic-list-unit animated fadeInUp">
                  <div className="statistic-box1">
                    <a href="https://t.me/+V6jBRSeYih82ZTM0" target="_blank">
                      <div className="text-center">
                        <FaTelegram
                          className="fa fa-telegram statistic-box__icon"
                          aria-hidden="true"
                        />
                        <div className="statistic-box__title">
                          Telegram Group
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="statistic-list-unit animated fadeInUp">
                  <div className="statistic-box1">
                    <a href="https://t.me/celexcommunity" target="_blank">
                      <div className="text-center">
                        <FaTelegram
                          className="fa fa-telegram statistic-box__icon"
                          aria-hidden="true"
                        />
                        <div className="statistic-box__title">
                          Teligram chennel
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="statistic-list-unit animated fadeInUp">
                  <div className="statistic-box1">
                    <a
                      href="https://x.com/celexworld2024?t=aI_5trPZBVGnvmvvheQwjA&s=08"
                      target="_blank"
                    >
                      <div className="text-center">
                        <FaTwitter
                          className="fa fa-twitter statistic-box__icon"
                          aria-hidden="true"
                        />
                        <div className="statistic-box__title">Twitter</div>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="statistic-list-unit animated fadeInUp">
                  <div className="statistic-box1">
                    <a href="https://www.youtube.com/@celexworldofficial">
                      <div className="text-center">
                        <TfiYoutube
                          className="fa fa-youtube statistic-box__icon"
                          aria-hidden="true"
                        />
                        <div className="statistic-box__title">Youtube</div>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* footer end */}
        </div>
      </div>
    </div>
  );
}

export default Home;
