import React, { useEffect, useState } from "react";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { Select } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Fleg from "../../Assets/Header/Flag.png";

export default function ReleaseRoiIncome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [userid, setUserId] = useState("");
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [status_val, setStatus] = useState("0");

  const ROIincome_API = async () => {
    try {
      let responce = await API?.post(
        `/releasedCycleRoiList`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("roi", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          Cycle: item.cc,
          investcount: item.investcount,
          Roi: item.Roi,
          status: item.IsClaimed === 1 ? "Claimed" : "Running",
          date: item.dd,
          remainday: item.remainday,
          claim:
            item.IsClaimed === 1 ? (
              <button type="button" className="btn btn-danger">
                Claimed
              </button>
            ) : (
              <button
                onClick={() => claimRoiIncome(item.investcount, item.Cycle)}
                type="button"
                className="btn btn-success btn-sm"
              >
                Claim
              </button>
            ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const claimRoiIncome = async (investmentCount, cycle) => {
    let confirm = window.confirm("Are you sure, you want to claim..?");
    if (confirm) {
      let responce = await API?.post(
        `/claimedRoiIncome`,
        {
          uid: userId,
          investcount: investmentCount,
          cycle: cycle,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      responce = responce.data.data;
      //console.log("claimedResult", responce);
      if (responce === "Claimed successfully done !!") {
        alert(responce);
        window.location.reload();
      } else {
        alert(responce);
      }
    }
  };

  //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }
  // Notification end
  useEffect(() => {
    ROIincome_API();
    getNotification();
  }, []);

  const handleChange_status = (value) => {
    setStatus(value);
  };

  const numberValidation = (e) => {
    const news = e.replace(/[^0-9]/, "").substring(0, 6);
    setUserId(news);
  };

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Roc Amount", accessor: "Roi" },
      { Header: "Cycle", accessor: "Cycle" },
      //   { Header: "investcount", accessor: "investcount" },
      //   { Header: "Status", accessor: "status" },
      { Header: "Claimed Date", accessor: "date" },
      { Header: "Remaining Day", accessor: "remainday" },
      { Header: "Claim", accessor: "claim" },
    ],
  });
  // Toggle sidebar function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <div className="container-fluid NewDashboard Activate_History">
        <div className="row">

          <div className={` ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <Sidebar />
          </div>
          <div className="col-12 ms-md-auto px-0">
            <div className="contentside">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <header className="header ps-0">
                    <div className="hdleft d-flex align-items-center">
                      <div className="namelogo ms-3">Clex</div>
                    </div>
                    <div className="rightside">
                      <div className="dropdown dropdow1 d-none d-sm-block">
                        <a
                          className="btn btnsecondary text-back"
                          href="/Notification"
                        >
                         🔔 <span className="btn headerbtn" > {NotificationCount} </span>
                        </a>
                      </div>
                      <div className="dropdown dropdow2 d-none d-md-block">
                        <Link className="btn btnsecondary dropdown-toggle" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <span className="text1">
                            <p className="mb-1">ID Number</p>
                            <p className="mb-0 fw-bold text-start">{userId} </p>
                          </span>
                          <span className="icon">
                            <div className="name"><img className="w-100 h-100" src="https://metaswift.ai/assets/img/user_pic.png" alt="" /></div>
                          </span>
                        </Link>

                        <ul className="dropdown-menu">
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        </ul>
                      </div>
                      <button className="btn headerbtn">Connect Wallet</button>
                      <button className="btn border-0 togglebtn" id="togglebtn" onClick={toggleSidebar}>
                        <i className={`fas fa-bars ${isSidebarOpen ? '' : 'collapsed'}`}></i>
                      </button>
                    </div>
                  </header>
                </div>
                <div className="col-12">
                  <div className="contentsidesec w-100 pt-3">
                    <div class="row mx-0">
                      <div class="col-12">
                        <div class="row mx-0 mt-3">
                          {/* <div class="col-md-2">
                    <label> User Id</label>
                    <input
                      type="text"
                      name="from_date"
                      id="uid"
                      onChange={(e) => numberValidation(e.target.value)}
                      class="form-control"
                      placeholder="Enter User Id"
                      value={userid}
                    />
                  </div>
  
                  <div class="col-md-2 mt-3">
                    <div className="mtr_space"></div>
                    <input
                      type="submit"
                      name="to_date"
                      value="Search"
                      class="btn btn-primary mt_5"
                    />
                  </div> */}
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="table-responsive">
                              <div
                                id="myAssets_wrapper"
                                class="dataTables_wrapper dt-bootstrap5 no-footer"
                              >
                                <div class="row">
                                  <div class="col-sm-12">
                                    <Table
                                      data={[...currentPost]}
                                      columns={matching_income.cols}
                                    />
                                    <Table_Buttons
                                      indexOfFirstPage={indexOfFirstPage}
                                      indexOfLastPost={indexOfLastPost}
                                      setcurrentPage={setcurrentPage}
                                      currentPage={currentPage}
                                      totalData={referralApi.length}
                                      listPerpage={listPerpage}
                                      handleRowsPerPageChange={setlistPerpage}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
