import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Fleg from "../../Assets/Header/Flag.png";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

function My_Referral() {
  const [userid, setUserId] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [status_val, setStatus] = useState("2");
  const [position, setPosition] = useState(0);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [totalClaimAmount, setTotalClaimAmount] = useState(0);
  const [totalinvestAmount, setTotalinvestAmount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const Direct_API = async () => {
    try {
      let responce = await API?.post(
        `/myDirectReport`,
        {
          uid: userId,
          searchid: search,
          fdate: "",
          tdate: "",
          status: 2,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Direct_API", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      let totalClaimAmount = 0;
      let totalinvestAmount = 0;
      responce.forEach((item, index) => {
        const claimAmount = parseFloat(item.claimAmount) || 0;
        totalClaimAmount += claimAmount;
        const investAmount = parseFloat(item.totalPackage) || 0;
        totalinvestAmount += investAmount;
        arr.push({
          sr: item.row,
          Registration_Date: item.registrationDate,
          User_ID: item.uid,
          Position: item.position,
          // Wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
          //   item.wallet.length - 4
          // )}`,
          Status: item.status,
          packagename: item.packagename,
          Latest_Package: item?.currentPackage,
          Gross_Package:
            item.status === "Active" ? <>{item?.totalPackage} USD</> : <></>,
          Activation_Date: item.activationDate,
          claimstatus: item.claimstatus,
          claimdate: item.claimdate,
          claimAmount: item.claimAmount,
        });
      });

      setreferralApi(arr);
      setTotalinvestAmount(totalinvestAmount);
      setTotalClaimAmount(totalClaimAmount);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            clubAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }
  // Notification end

  useEffect(() => {
    Direct_API();
    getNotification();
  }, []);

  const handleChange_status = (value) => {
    // console.log(`handleChange_status ${value}`);
    setStatus(value);
  };

  const handleChange_package = (value) => {
    // console.log(`handleChange_status ${value}`);
    setPosition(value);
  };

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "User_ID" },
      { Header: "Reg. Date & Time", accessor: "Registration_Date" },
      { Header: "Status", accessor: "Status" },
      { Header: "Investment", accessor: "Gross_Package" },
      { Header: "Activation Date & Time", accessor: "Activation_Date" },
      { Header: "Claim Amount", accessor: "claimAmount" },
      { Header: "Claim Status", accessor: "claimstatus" },
      { Header: "Claim Date & Time", accessor: "claimdate" },
    ],
  });

  function numberValidation(e) {
    e.target.value = e.target.value.replace(/[^0-9]/, "").substring(0, 6);
    setUserId(e.target.value);
  }
  // Toggle sidebar function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="container-fluid NewDashboard Activate_History">
      <div className="row">

        <div className={` ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          <Sidebar />
        </div>
        <div className="col-12 ms-md-auto px-0">
          <div className="contentside">
            <div className="row mx-0">
              <div className="col-12 px-0">
                <header className="header ps-0">
                  <div className="hdleft d-flex align-items-center">
                    <div className="namelogo ms-3">My Volunteer</div>
                  </div>
                  <div className="rightside">
                    <div className="dropdown dropdow1 d-none d-sm-block">
                      <a
                        className="btn btnsecondary text-back"
                        href="/Notification"
                      >
                        🔔 <span className="btn headerbtn" > {NotificationCount} </span>
                      </a>
                    </div>
                    <div className="dropdown dropdow2 d-none d-md-block">
                      <Link className="btn btnsecondary dropdown-toggle" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="text1">
                          <p className="mb-1">ID Number</p>
                          <p className="mb-0 fw-bold text-start">{userId} </p>
                        </span>
                        <span className="icon">
                          <div className="name"><img className="w-100 h-100" src="https://metaswift.ai/assets/img/user_pic.png" alt="" /></div>
                        </span>
                      </Link>

                      <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                      </ul>
                    </div>
                    <button className="btn headerbtn">Connect Wallet</button>
                    <button className="btn border-0 togglebtn" id="togglebtn" onClick={toggleSidebar}>
                      <i className={`fas fa-bars ${isSidebarOpen ? '' : 'collapsed'}`}></i>
                    </button>
                  </div>
                </header>
              </div>
              <div className="col-12">
                <div className="contentsidesec w-100 pt-3">
                  <div className="row mx-0">
                    <div class="col-md-4">
                      <span>Total Investment - {totalinvestAmount} USD </span>
                    </div>
                    <div class="col-md-4">
                      <span>Total Claim - {totalClaimAmount} USD</span>
                    </div>
                    <div class="row mt-4 mx-0" style={{ display: "none" }}>
                      <div class="col-md-2">
                        <label> User Id</label>
                        <input
                          type="text"
                          name="from_date"
                          id="uid"
                          onChange={numberValidation}
                          class="form-control"
                          placeholder="Enter User Id"
                          value={userid}
                        />
                      </div>
                      {/* <div class="col-md-2">
                        <label> From Date</label>
                        <input
                          type="date"
                          name="from_date"
                          onChange={e => setToDate(e.target.value)}
                          id="from_date"
                          class="form-control"
                          placeholder="dd-mm-yyyy"
                        />
                      </div>
                      <div class="col-md-2">
                        <label> To Date</label>
                        <input
                          type="date"
                          placeholder="dd-mm-yyyy"
                          onChange={e => setFromDate(e.target.value)}
                          name="to_date"
                          id="to_date"
                          class="form-control"
                        />
                      </div> */}
                      {/* <div class="col-md-2">
                        <label>Position</label>
                        <select
                          id="position"
                          onChange={e => setPosition(e.target.value)}
                          class="floating-input form-control select_bg"
                          data-val="true"
                          data-val-required="Position is required"
                        >
                        <option value="Select Position">Select Position</option>
                        <option value="0">All</option>
                        <option value="1">Left</option>
                        <option value="2">Right</option>
                      </select>
                    </div> */}
                      {/* <div className="col-md-2">
                        <label>Choose Package</label>
                        <Select
                          defaultValue=" All"
                          id="pack"
                          className="floating-input select_bg"
                          data-val="true"
                          data-val-required="Package is required"
                          onChange={handleChange_package}
                          options={[
                            {
                              value: "0",
                              label: "All",
                            },
                            {
                              value: "1",
                              label: "25 USD",
                            },
                            {
                              value: "2",
                              label: "50 USD",
                            },
                            {
                              value: "3",
                              label: "100 USD",
                            },
                            {
                              value: "4",
                              label: "200 USD",
                            },
                            {
                              value: "5",
                              label: "500 USD",
                            },
                            {
                              value: "6",
                              label: "1000 USD",
                            },
                          ]}
                        />
                      </div> */}
                      <div className="col-md-2">
                        <label>Choose Status</label>
                        <Select
                          defaultValue=" Select status"
                          id="position"
                          className="floating-input select_bg"
                          data-val="true"
                          data-val-required="Position is required"
                          onChange={handleChange_status}
                          options={[
                            {
                              value: "2",
                              label: "All",
                            },
                            {
                              value: "1",
                              label: "Active",
                            },
                            {
                              value: "0",
                              label: "In-Active",
                            },
                          ]}
                        />
                      </div>
                      <div class="col-md-2 mt-3">
                        <div className="mtr_space"></div>
                        <input
                          type="submit"
                          name="to_date"
                          value="Search"
                          onClick={{ Direct_API }}
                          class="btn btn-primary mt_5"
                        />
                      </div>
                    </div>

                    <div class="col-xl-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <div
                              id="myAssets_wrapper"
                              class="dataTables_wrapper dt-bootstrap5 no-footer">
                              <div class="row mx-0">
                                <div class="col-sm-12">
                                  <Table
                                    data={[...currentPost]}
                                    columns={matching_income.cols}
                                  />
                                  <Table_Buttons
                                    indexOfFirstPage={indexOfFirstPage}
                                    indexOfLastPost={indexOfLastPost}
                                    setcurrentPage={setcurrentPage}
                                    currentPage={currentPage}
                                    totalData={referralApi.length}
                                    listPerpage={listPerpage}
                                    handleRowsPerPageChange={setlistPerpage}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default My_Referral;
