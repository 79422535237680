import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { loadWeb3 } from "../../Utility/contract";
import toast from "react-hot-toast";
import "./Login.css";
import { updateAuth } from "../../Redux/AuthSlice";
export default function Login() {
  let navigate = useNavigate();

  const [LoginId, setLoginId] = useState("");
  const [connected, setconnected] = useState(true);
  const [ipAddress, setIP] = useState("");
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");

      setIP(response.data.ip);
      let ipaddress = response.data.ip;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);

        if (acc !== "") {
          let res = await API.post(`/login`, {
            password: acc,
            ipaddress: ipaddress,
          });

          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            console.log("res", res.data);

            dispatch(
              updateAuth({
                isAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/Dashboard");
          } else {
            toast.error(res.data.data.result);
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const onSubmitHandler = async () => {
    let response = await axios.get("https://api64.ipify.org/?format=json");
    console.log("response Geolocation", response.data.ip);
    setIP(response.data.ip);
    let ipaddress = response.data.ip;
    let res = await API.post(`/login`, {
      password: LoginId,
      ipaddress: ipaddress,
    });

    if (res.data.data.result === "Successfull") {
      toast.success(res.data.data.result);

      dispatch(
        updateAuth({
          isAuth: true,
          userId: res.data.data.uid_output,
          jwtToken: res.data.token,
          ipAddress: ipaddress,
        })
      );
      navigate("/Dashboard");
    } else {
      toast.error(`Invalid User id or Address`);
    }
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      walletConnected();
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="container-fluid loginpage vh-100">
        <div className="row h-100 align-items-center">
          <div className="col-md-4 col-sm-6 col-12 h-100 d-flex justify-content-center align-items-center h-100 flex-column  mx-auto mt-4 mt-sm-0">
            <div className="mt-5 mt-sm-0">
              <div className="card cardlogin position-relative mt-5 mt-sm-0">
                <div className="card-body p-0">
                  <div className="row mx-0 gy-2 h-100 g-3 ">
                    <div className="col-12">
                      <div className="loginheading text-center">Welcome Back</div>
                      <div className="loginheading2 text-center">Please Login to Your Account</div>
                      <div className="subtext text-center">
                        {" "}
                        Remember to authorize with the correct address.
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="userInput">
                        Please Enter ID or Address
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your ID or  BNB Address"
                        id="userInput"
                        className="control-form mb-2"
                        // value={LoginId}
                        // onChange={(e) => setLoginId(e.target.value)}
                      readOnly
                      />
                    </div>
                    <div className="col-12">
                      <button className="btn logibtn newcolor px-4">
                        If you have already registered then
                        <Link
                          className="Anker"
                          to=""
                          onClick={() => {
                            onSubmitHandler();
                          }}
                        >
                          Login
                        </Link>
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext text-center">
                        Don’t have an account?{" "}
                        <Link className="Anker" to="/register">
                          {" "}
                          Create Account{" "}
                        </Link>
                      </div>
                      <div className="bottomtext">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
