import React, { useEffect, useState } from "react";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { Select } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Fleg from "../../Assets/Header/Flag.png";


export default function SalaryIncome() { 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [userid, setUserId] = useState("");
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [status_val, setStatus] = useState("0");

  const SalaryIncome_API = async () => {
    try {
      let responce = await API?.post(
        `/SalaryIncomeReport`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("roi", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          sid: item.sid,
          selfbv: item.selfbv,
          teambv: item.teambv,
          // amount: item.InvestmentAmount,
          // status: item.status === 1 ? "Active" : "Inactive",
          date: item.dd,
          income: item.income,
          // Wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
          //   item.wallet.length - 4
          // )}`,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
 //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }
  // Notification end
  useEffect(() => {
    SalaryIncome_API();
    getNotification();
  }, []);

  const handleChange_status = (value) => {
    setStatus(value);
  };

  const numberValidation = (e) => {
    const news = e.replace(/[^0-9]/, "").substring(0, 6);
    setUserId(news);
  };

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      // { Header: "From Id", accessor: "fromid" },
      // { Header: "Name", accessor: "f_name" },
      // { Header: "Slot", accessor: "on_amount" },
      // { Header: "Amount", accessor: "amount" },
      { Header: "Income", accessor: "income" },
      // { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "date" },
    ],
  });
  // Toggle sidebar function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <div className="container-fluid NewDashboard Activate_History">
        <div className="row">

          <div className={` ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <Sidebar />
          </div>
          <div className="col-12 ms-md-auto px-0">
            <div className="contentside">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <header className="header ps-0">
                    <div className="hdleft d-flex align-items-center">
                      <div className="namelogo ms-3">Salary Income</div>
                    </div>
                    <div className="rightside">
                    <div className="dropdown dropdow1 d-none d-sm-block">
                        <a
                          className="btn btnsecondary text-back"
                          href="/Notification"
                        >
                          🔔 <span className="btn headerbtn" > {NotificationCount} </span>
                        </a>
                      </div>
                      <div className="dropdown dropdow2 d-none d-md-block">
                        <Link className="btn btnsecondary dropdown-toggle" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <span className="text1">
                            <p className="mb-1">ID Number</p>
                            <p className="mb-0 fw-bold text-start">{userId} </p>
                          </span>
                          <span className="icon">
                            <div className="name"><img className="w-100 h-100" src="https://metaswift.ai/assets/img/user_pic.png" alt="" /></div>
                          </span>
                        </Link>

                        <ul className="dropdown-menu">
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                          <li><Link className="dropdown-item" to="#"><span> <img className="w-100 h-100" src={Fleg} alt="" /></span>USA</Link></li>
                        </ul>
                      </div>
                      <button className="btn headerbtn">Connect Wallet</button>
                      <button className="btn border-0 togglebtn" id="togglebtn" onClick={toggleSidebar}>
                        <i className={`fas fa-bars ${isSidebarOpen ? '' : 'collapsed'}`}></i>
                      </button>
                    </div>
                  </header>
                </div>
                <div className="col-12">
                  <div className="contentsidesec w-100 pt-3">
                    <div class="row mx-0">
                      <div class="col-12">
                        <div class="row mx-0 mt-3">
                          {/* <div class="col-md-2">
              <label> User Id</label>
              <input type="text" name="from_date" id="uid" onChange={(e)=> numberValidation(e.target.value)}
              class="form-control"
              placeholder="Enter User Id"
              value={userid}
              />
            </div>

            <div class="col-md-2 mt-3">
              <div className="mtr_space"></div>
              <input type="submit" name="to_date" value="Search" class="btn btn-primary mt_5" />
            </div> */}
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="table-responsive">
                              <div id="myAssets_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div class="row">
                                  <div class="col-sm-12">
                                    <Table data={[...currentPost]} columns={matching_income.cols} />
                                    <Table_Buttons indexOfFirstPage={indexOfFirstPage} indexOfLastPost={indexOfLastPost}
                                      setcurrentPage={setcurrentPage} currentPage={currentPage} totalData={referralApi.length}
                                      listPerpage={listPerpage} handleRowsPerPageChange={setlistPerpage} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
