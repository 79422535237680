import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar.jsx";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import { Select } from "antd";
import { loadWeb3 } from "../../Utility/contract.js";
import toast from "react-hot-toast";
import Fleg from "../../Assets/Header/Flag.png";
import { Link, useNavigate } from "react-router-dom";

function Withdrawal() {
  let [Amount, setAmount] = useState("0");
  let [Token, setToken] = useState("0");
  let [withdrawalType, setwithdrawalType] = useState("Select Withdrawal Type");
  let [paymentType, setPaymentType] = useState("Select");
  const [loader, setloader] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpvalid, setOTPValid] = useState("0");
  const [TotalWithdrawl, setTotalWithdrawl] = useState(0);
  const [Netbal, setNetBal] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [waddress, setwaddress] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [Modaldata, setModalData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [dash, setdataArray] = useState([]);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      //console.log("responceDashboard", responce);

      setdataArray(responce);
   
   
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
        );
        navigate("/");
      }
    }
  };  

  const numberValidation = async (e) => {
    const newValues = e.replace(/[^0-9]/gi, "").substring(0, 6);
    setAmount(newValues);

    try {
      let responce = await API?.post(`/getUsdToTokenValue`, {
        TokenValue: 0,
        EthValue: 0,
        USDValue: newValues,
        type: "Buy",
      });
      responce = responce.data.data[0][0].Token;
      setToken(responce);
      console.log("netbalance", responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (e.response.status === 401) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Net_Balance = async () => {
    try {
      let responce = await API?.get(
        `/userDashboard?uid=${userId}`,

        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("netbalance", responce.data.data[0][0]);
      responce = responce.data.data[0][0].Net_Balance;
      setNetBal(responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  let [connected, setconnected] = useState("Wallet is locked");

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
        toast.error("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
        toast.error("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
        toast.success("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Withdrawal_API = async () => {
    try {
      if (connected == "Wallet is Connected") {
        let responce = await API?.post(
          `/withdrawal`,
          {
            uid: userId,
            address: "",
            amount: Amount,
            tokenvalue: Token,
            withdrawalType: withdrawalType,
            payment_type: paymentType,
          },
          {
            headers: {
              Authorization: `${jwtToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        //console.log("result", responce.data.data);
        responce = responce.data.data;
        if (responce === "SUCCESSFUL") {
          alert(responce);
          window.location.reload();
        } else {
          alert(responce);
        }
      } else {
        toast.error("Connect Wallet");
      }
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  //Notification start
  const [NotificationCount, setNotificationCount] = useState("");
  const getNotification = async () => {
    try {
      let res = await API.get(`/GetNotificationuserCount?uid=${userId}`);
      setNotificationCount(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  };
  // Notification end
  useEffect(() => {
    walletConnected();
    getNotification();
  }, [connected]);

  useEffect(() => {
    Dashboard_API();
  }, []);

  

  const handleType = async (value) => {
    setwithdrawalType(value);
    try {
      let responce = await API?.post(
        `/getNetBalance`,
        {
          uid: userId,
          type: value,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data;
      setNetBal(responce);
      //console.log("netbalance", responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (e.response.status === 401) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const handlePayment = async (value) => {
    setPaymentType(value);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle sidebar function
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="container-fluid NewDashboard Working_Withdrawalpage">
      <div className="row">
        <div
          className={` ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
          <Sidebar />
        </div>
        <div className="col-12 ms-md-auto px-0">
          <div className="contentside">
            <div className="row mx-0">
              <div className="col-12 px-0">
                <header className="header ">
                  <div className="hdleft d-flex align-items-center">
                    <div className="namelogo ms-sm-3">Withdrawal</div>
                  </div>
                  <div className="rightside">
                    <div className="dropdown dropdow2 d-none d-md-block">
                      <Link
                        className="btn btnsecondary dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="text1">
                          <p className="mb-1">ID Number</p>
                          <p className="mb-0 fw-bold text-start">{userId} </p>
                        </span>
                        <span className="icon">
                          <div className="name">
                            <img
                              className="w-100 h-100"
                              src="https://metaswift.ai/assets/img/user_pic.png"
                              alt=""
                            />
                          </div>
                        </span>
                      </Link>

                      <ul className="dropdown-menu p-2">
                        <li className="text-white walltadd">
                          {dash.btcaddress}
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown dropdow2 d-block d-md-none">
                      <Link
                        className="btn btnsecondary dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="icon">
                          <div className="name">
                            <img
                              className="w-100 h-100"
                              src="https://metaswift.ai/assets/img/user_pic.png"
                              alt=""
                            />
                          </div>
                        </span>
                      </Link>

                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#">
                            Id Number <span>{userId}</span>
                          </Link>
                        </li>
                        <li className="text-white walltadd">
                          {dash.btcaddress}
                        </li>
                      </ul>
                    </div>
                    <button className="btn headerbtn">Connect Wallet</button>
                    <button
                      className="btn border-0 togglebtn"
                      id="togglebtn"
                      onClick={toggleSidebar}
                    >
                      <i
                        className={`fas fa-bars ${
                          isSidebarOpen ? "" : "collapsed"
                        }`}
                      ></i>
                    </button>
                  </div>
                </header>
              </div>
              <div className="col-12">
                <div className="row mx-0 contentsidesec w-100 pt-3">
                  <div class="col-md-6 mx-auto width-mg card1">
                    <div class="row mx-0" id="withdraw-inp">
                      <div className="row mx-0">
                        <div className="col-12 px-0">
                          <h2 class="py-2 fw-bold border_bottom text-center">
                            {" "}
                            Withdrawal
                          </h2>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="col-12 px-0  text-md-start">
                          <h5 class="py-2 border_bottom fw-bold">
                            {" "}
                            {connected}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-5 d-flex align-items-center">
                        <p className="m-0 p-0 text-white fw-bold">
                          Withdrawal Type
                        </p>
                      </div>
                      <div className="col-md-7">
                        <Select
                          value={withdrawalType}
                          id="withdrawalType"
                          className=" profile-border w-100"
                          data-val="true"
                          data-val-required="withdrawal Type is required"
                          onChange={(value) => handleType(value)}
                          style={{
                            height: "40px",
                            borderRadius: "0px !important",
                          }}
                          options={[
                            {
                              value: "LevelIncome",
                              label: "Greade Withdrawal",
                            },
                            {
                              value: "PrincipleIncome",
                              label: "Principle Withdrawal",
                            },
                            {
                              value: "OtherIncome",
                              label:
                                "ROC + Royalty + Reward Withdrawal + Salary",
                            },
                          ]}
                        />
                      </div>

                      <div className="col-12 mt-3 px-0">
                        {withdrawalType === "OtherIncome" ? (
                          <div className="row mx-0 align-items-center ">
                            <div className="col-md-5">
                              <p className="m-0 p-0 text-white fw-bold">
                                Payment Type
                              </p>
                            </div>
                            <div className="col-md-7 ">
                              <Select
                                value={paymentType}
                                id="withdrawalType"
                                className="w-100"
                                data-val="true"
                                data-val-required="withdrawal Type is required"
                                onChange={(value) => handlePayment(value)}
                                style={{
                                  height: "40px",
                                  borderRadius: "0px !important",
                                }}
                                options={[
                                  {
                                    value: "USD",
                                    label: "USD",
                                  },
                                  {
                                    value: "Token",
                                    label: "Celex",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col-12 py-3">
                        <p class="text-white fw-bold">Wallet Net USDT Value</p>
                        <input
                          type="text"
                          class="p-2 profile-border w-100"
                          value={Netbal}
                          readOnly
                        />
                      </div>
                      <div className="row mx-0 my-3">
                        <div className="col-12 px-0">
                          <p class="text-white fw-bold">Enter USD Amount</p>
                          <input
                            type="text"
                            class="p-2 profile-border w-100"
                            value={Amount}
                            onChange={(e) => numberValidation(e.target.value)}
                          />
                        </div>
                      </div>
                      {(withdrawalType === "LevelIncome" ||
                        withdrawalType === "OtherIncome") &&
                        paymentType !== "USD" && (
                          <div className="row mx-0 align-items-center">
                            <div className="col-12 px-0">
                              <p className="p-0 text-white fw-bold">
                                Celex Amount
                              </p>
                            </div>
                            <div className="col-12 px-0">
                              <input
                                type="text"
                                className="p-2 profile-border w-100"
                                value={Token}
                                readOnly
                              />
                            </div>
                          </div>
                        )}

                      {/* <div class="col-11 mt-3 row align-items-center">
                              <p class="col-5 m-0 p-0">OTP</p>
                              <input type="text" class="p-2 profile-border col-7" maxlength="10" />
                            </div> */}

                      <div className="col-12">
                        <button
                          class=" mt-3 py-3 px-5 d-flex mx-auto wdth_btn btn mb-3 activate_btn fw-bold"
                          onClick={() => Withdrawal_API()}
                          style={{ color: "#000" }}
                        >
                          Proceed
                        </button>
                      </div>

                      <div className="text-center">
                        <span style={{ color: "red" }}>
                          * It will take a maximum 24 hours to credit your
                          amount in wallet .
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdrawal;
